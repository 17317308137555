import React, { useEffect, useState } from 'react';
import { mernstack } from '../../Data/DataFetcher';
import Loading from '../../Modals/Loading';
import Languages from '../../components/Languages';
import CourseCertificate from '../../components/CourseCertificate';
import LandDreamJob from '../../components/LandDreamJob';
import Companies from '../../components/companies/Companies';
import CourseOffering from '../../components/Offering/CourseOffering';
import Benifits from '../../components/Benifits/Benifits';
import Adwantages from '../../components/advantages/Adwantages';
import FaqSection from '../../components/FaqSection';
import Feedback from '../../components/Feedback';
import DownloadModal from '../../components/Brocher/DownloadModal';
import { formatDate } from '../../js/utils';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import CourseHero from './CourseHero';
import Modules from './Modules';
import CountDown from '../../Extra/countdown/CountDown';

function MernCourse() {
    const [loading, setLoading] = useState(true);
    const [courseData, setCourseData] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [brocher, setBrocher] = useState(null)

    useEffect(() => {
        mernstack
          .then((data) => {
            setCourseData(data);
            setLoading(false);
            console.log("courses", data);
          })
          .catch((error) => console.error('Error fetching courses:', error));
    }, []);

    useEffect(() => {
      setBrocher(courseData.BrocherLink)
        if (courseData.courseName) {
            document.title = courseData.courseName;
        }
    }, [courseData]);
    console.log(brocher);


    const handleWhatsappShare = () => {
        const messageContent = `
        *Hello!*
        _This message is from the software development training program._
        *${courseData.courseName}*
        *${courseData.seo?.description}*
        *New Batch Starting:* ${formatDate(courseData.details?.admisionStart)}

        [Click Here to Explore This Course](https://be-practical.com)

        *You may also like these courses:*
        - Mern Fullstack Development
        - Python Fullstack Development
        - Java Fullstack Development
        - Cloud Computing
        - Digital Marketing
        - Job Oriented Courses
        - ...and more

        [Explore More Courses](https://be-practical.com)

        _Thank You_
        `;
        const encodedMessage = encodeURIComponent(messageContent);
        window.open(`https://wa.me/?text=${encodedMessage}`, '_blank');
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    if (loading) {
        return <Loading />;
    }

    const { seo, courseName, heroSubtitle, coursePoints, programmingLanguages, BrocherLink, faqs } = courseData;

  

    return (
        <section className='overflow-hidden'>
          <Helmet>
          <title>Mern Stack Course | Mern Stack Developer Course | Mern Stack Training</title>
          <meta name="description" content="Learn Mern Stack Developer Course Enroll for the comprehensive mern stack course at Be-Practical.com to get mern stack training from industry expert." />
          <meta name="keywords" content="Mern Stack Developer Course" />
          <link rel="canonical" href="https://be-practical.com/mern-stack-development-training" />
        </Helmet>
    
          <CourseHero 
            coursename={courseName} 
            coursesubtitle={heroSubtitle} 
            admisionStart={courseData.details.admisionStart} 
            coursePoints={coursePoints} 
            courseData={courseData} 
            brocherLink={BrocherLink} 
            download={openModal} 
            share={handleWhatsappShare}
          />
    
          <Modules courseData={courseData} />
          <Languages languages={programmingLanguages} course={courseName} />
          {/* <CountDown date={courseData.details.admisionStart} /> */}
          <CourseCertificate data={courseData} />
          <LandDreamJob />
          <Companies />
          <CourseOffering />
          <Benifits data={courseData} />
          <Adwantages />
          <FaqSection faq={faqs} />
          <Feedback />
          <DownloadModal showModal={showModal} hideModal={closeModal} link={courseData} />
        </section>
    );
}

export default MernCourse;
